<template>
  <div class="informer" bi-route="xianbao">
    <div class="informerLeft">
      <div class="head">
        <div class="title">
          <span class="text">优惠线报</span>
          <span>淘宝/京东最全最新的一手隐藏优惠，还可和优惠券叠加使用！</span>
        </div>
        <!-- <div class="more" bi-click="gengduo">
          <p>查看更多</p>
          <img src="@/assets/search/right2.png" alt="" />
        </div> -->
      </div>
      <div class="tabs">
        <div
          class="tabItem"
          :class="[{ checkItem: checktab.id == item.id }]"
          v-for="(item, index) in tabList"
          :key="item.id"
          @click="checktab = item"
          bi-click="fenlei"
          :data-index="index"
          :data-gid="item.title"
        >
          {{ item.title }}
          <div class="arrow_down"></div>
        </div>
      </div>
      <div class="goodsList">
        <div
          class="goodsItem"
          v-for="(item, index) in dataList"
          :key="item.id"
          @click="handleUrl(item, index)"
          :id="`qujianlou${index}`"
          bi-route="goods"
        >
          <div class="goodsHead">
            <img v-if="item.picUrls" :src="item.picUrls" alt="" />
            <img v-else src="./images/deful.png" alt="" />
            <div v-html="handleContent(item.contentCopy)"></div>
          </div>
          <div class="goodsTime">
            <img :src="getShopImg(checktab.type)" alt="" />
            <div>发布于{{ handleTime(item.createTime) }}</div>
          </div>
          <div class="goodsBut">
            <p>去捡漏</p>
            <img src="@/assets/search/rightbai.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="informerLRight">
      <div class="title">
        <span class="text">优惠活动中心</span>
        <span>爆款活动 万里挑一</span>
      </div>
      <div class="imgList">
        <div v-for="(item, index) in absList" :key="item.img">
          <div v-if="index < 2">
            <a
              bi-click="guanggao"
              :data-title="item.title"
              :data-index="index"
              :data-bi360="bi360"
              :href="item.url"
              target="_blank"
            >
              <img v-lazy="`${item.img}`" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getlistTipOff,
  transformLink,
  getJDPromotionUnionConvert,
} from "@/services";
import { importCDN } from "@/utils/utils";
import tianmao from "@/assets/search/prod_icon3.png";
export default {
  components: {},
  computed: {
    ...mapGetters(["marketGroup", "config"]),
  },
  watch: {
    marketGroup(state) {
      this.absList = state.ad_360_xb_right;
      this.bi360 = state.bi360;
    },
    checktab(state) {
      this.getlistTipInfo();
    },
  },
  methods: {
    getlistTipInfo() {
      getlistTipOff({
        topic: this.checktab.id,
        type: this.checktab.type,
        pageSize: 4,
      }).then((res) => {
        this.dataList = res.data.list;
      });
    },
    handleUrl(item, index) {
      if (item.platform == "京东") {
        this.jdTransform(item).then((res) => {
          try {
            this.$BIJsdk.onEvent({
              event: document.getElementById(`qujianlou${index}`),
              extra: {
                pingtai: "jd",
                ...res,
              },
              event_type: "click",
            });
          } catch (error) {}
        });
      } else {
        this.tbTransform(item).then((res) => {
          try {
            this.$BIJsdk.onEvent({
              event: document.getElementById(`qujianlou${index}`),
              extra: {
                pingtai: "tb",
                ...res,
              },
              event_type: "click",
            });
          } catch (error) {}
        });
      }
    },
    //京东转链
    jdTransform(item) {
      let fid = item.itemIds.split(",")[0];
      const data = {
        c_uid: 0,
        cps_uid: 0,
        column: 0,
        auth_id: this.config.jd.auth_id, //授权id
        pid: this.config.jd.pid || "", //pid
        source: "CMS",
        service: "C",
        materialId: `https://item.jd.com/${fid}.html`,
        unionId: this.config.jd.union_id,
        couponUrl: "",
      };
      const _data = window.Base64.encodeURI(JSON.stringify(data));
      return new Promise((resolve, reject) => {
        getJDPromotionUnionConvert({ p: _data }).then((res) => {
          if (res && res.code == 1) {
            resolve(data);
            window.open(res.data.shortURL, "_blank");
          }
        });
      });
    },
    //淘宝转链
    tbTransform(item) {
      let timestamp = new Date().getTime();
      const data = {
        site_id: this.config.taobao.uid,
        goodsid: item.itemIds,
        gid: item.itemIds,
        pid: this.config.taobao.pid,
        need_tpwd: 0,
        need_short_link: 1,
        auth_id: this.config.taobao.auth_id, //授权id
        not_use_commission_plan: 1, //1不使用高佣计划
        is_auto_quan: 1,
        need_item_link: 0,
        time: timestamp,
      };
      const _data = window.Base64.encodeURI(JSON.stringify(data));
      return new Promise((resolve, reject) => {
        transformLink({
          p: _data,
          ds: "cms-pc",
          referer: window.encodeURIComponent(location.href),
        }).then((res) => {
          if (res && res.code == 1) {
            resolve(data);
            window.open(res.data.ShortLink || res.data.Link, "_blank");
          }
        });
      });
    },
    handleContent(content) {
      return content
        .replace(/(\n)\[淘口令请转链\]\/\//g, "")
        .replace(/(\n)\[淘口令请转链\]\/\//g, "")
        .replace(/(\n)\[淘口令请转链\]\//g, "")
        .replace(/(\n)\[淘口令请转链\]/g, "")
        .replace(/(\n)\[京东请转链\]/g, "");
      // .replace(/(\r\n)|(\n)|(\r)/g, "<br />");
    },
    getShopImg(state) {
      if (state == 1) return require("@/assets/search/prod_icon1.png");
      if (state == 2) return require("@/assets/search/prod_icon3.png");
      if (state == 3) return require("@/assets/search/prod_icon4.png");
      return require("@/assets/search/prod_icon1.png");
    },
    handleTime(createTime) {
      var time1 = new Date(createTime).valueOf() / 1000;
      var minute = Math.floor((Math.floor(Date.now() / 1000) - time1) / 60),
        result = "";
      if (minute > 30) {
        result = createTime;
      } else if (minute < 1) {
        result = "1分钟之前";
      } else {
        result = `${minute}分钟之前`;
      }
      return result;
    },
  },
  mounted() {
    importCDN("https://public.ffquan.cn/lib/base64.min.js");
    this.getlistTipInfo();
    this.absList = this.marketGroup.ad_360_xb_right;
    this.bi360 = this.marketGroup.bi360;
  },
  data() {
    return {
      absList: [],
      tianmao,
      tabList: [
        {
          id: 1,
          type: 1,
          title: "淘宝好价",
        },
        {
          id: 3,
          type: 2,
          title: "猫超爆款",
        },
        {
          id: 7,
          type: 3,
          title: "京东好价",
        },
      ],
      checktab: {
        id: 1,
        type: 1,
        title: "淘宝好价",
      },
      dataList: [],
      bi360: 1,
    };
  },
};
</script>


<style scoped lang="less">
.informer {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .informerLeft {
    padding: 0 20px;
    box-sizing: border-box;
    width: 820px;
    height: 494px;
    background: #ffffff;
    .head {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      align-items: center;
      .title {
        font-size: 12px;
        color: #999999;
        .text {
          font-weight: Bold;
          font-size: 24px;
          color: #333333;
          margin-right: 10px;
        }
      }
      .more {
        font-size: 13px;
        color: #333333;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 6px;
          height: 11px;
          margin-left: 5px;
        }
      }
    }
    .tabs {
      display: flex;
      margin-top: 20px;
      .tabItem {
        width: 140px;
        height: 38px;
        background-image: linear-gradient(116deg, #fffaf2 5%, #fff5f3 100%);
        font-size: 16px;
        color: #ea2c13;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        .arrow_down {
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 6px solid #ea2c13;
          margin-left: 62px;
          display: none;
        }
      }
      .checkItem {
        background: #ea2c13;
        font-weight: Bold;
        color: #ffffff;
        .arrow_down {
          display: block;
        }
      }
    }
    .goodsList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5px;
      .goodsItem {
        width: 385px;
        height: 172px;
        background: #f4f4f4;
        margin-top: 10px;
        padding: 10px 15px 10px 10px;
        box-sizing: border-box;
        cursor: pointer;
        .goodsHead {
          display: flex;
          font-size: 15px;
          color: #333333;
          white-space: pre-line;
          overflow: hidden;
          div {
            max-height: 110px;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            line-height: 1.5;
          }
          img {
            width: 125px;
            height: 125px;
            border-radius: 10px;
            margin-right: 15px;
          }
        }
        .goodsTime {
          display: flex;
          font-size: 12px;
          color: #888888;
          align-items: center;
          margin-top: 10px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
        }
        .goodsBut {
          width: 85px;
          height: 32px;
          background-image: linear-gradient(90deg, #ff6b51 0%, #ff0012 99%);
          font-size: 14px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          left: 275px;
          top: -31px;
          img {
            width: 8px;
            height: 10px;
            margin: 1px 0 0 3px;
          }
        }
      }
    }
  }
  .informerLRight {
    width: 360px;
    height: 494px;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    .title {
      font-size: 12px;
      color: #999999;
      .text {
        font-weight: Bold;
        font-size: 24px;
        color: #333333;
        margin-right: 10px;
      }
    }
    .imgList {
      img {
        width: 320px;
        height: 191px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>