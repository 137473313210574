var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "containerT" },
    [
      _c("div", { staticClass: "goodsCapition" }, [
        _vm._m(0),
        _c("div", { staticClass: "rightText" }, [
          _c("span", [_vm._v("今日已领优惠券")]),
          _c("span", [_vm._v(_vm._s(_vm.saveMoneyValue))])
        ])
      ]),
      _c(
        "div",
        { staticClass: "goodList", attrs: { "bi-route": "haohuo" } },
        _vm._l(_vm.goodsList, function(item, index) {
          return _c(
            "a",
            {
              key: item.id,
              staticClass: "itemCard",
              attrs: {
                "bi-click": "goods",
                "data-gid": item.id || item.goodsId,
                "data-index": index,
                target: "_blank"
              },
              on: {
                click: function($event) {
                  return _vm.handleItemClick(item)
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "marketgroup",
                      rawName: "v-marketgroup",
                      value: item,
                      expression: "item"
                    }
                  ]
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: item.mainPic + "_310x310.jpg",
                        expression: "`${item.mainPic}_310x310.jpg`"
                      }
                    ],
                    staticClass: "main_img"
                  })
                ]
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "?r=l/d&u=1&id=" + item.id + "&from=dtk",
                    target: "_blank"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row1" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.getShopImg(
                          item.haitao,
                          item.tchaoshi,
                          item.shopType
                        ),
                        alt: ""
                      }
                    }),
                    _c(
                      "span",
                      { attrs: { title: item.dtitle || item.title } },
                      [_vm._v(_vm._s(item.dtitle || item.title))]
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "row2" }, [
                item.brandName
                  ? _c("span", { staticClass: "tag" }, [
                      _vm._v(_vm._s(item.brandName))
                    ])
                  : _vm._e(),
                item.couponPrice
                  ? _c("span", { staticClass: "tag" }, [
                      _vm._v("券" + _vm._s(item.couponPrice) + "元")
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "row3" }, [
                _c("span", [
                  _c("span", { staticClass: "curr" }, [_vm._v("￥")]),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(item.actualPrice))
                  ])
                ]),
                _c("span", { staticClass: "sale" }, [
                  _vm._v(
                    " 已售" +
                      _vm._s(_vm._f("salesNum")(item.monthSales, _vm._, 1)) +
                      "件"
                  )
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("intersection-oberver-loading", {
        on: { onScroll: _vm.handleIntersection }
      }),
      _vm.visible
        ? _c("MicroDetail", {
            attrs: { visible: _vm.visible, id: _vm.microDetailId },
            on: { onClose: _vm.handleMicroDetailClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "text1" }, [_vm._v("精选推荐")]),
      _c("span", { staticClass: "text2" }, [
        _vm._v(" 人工精选推荐，每日更新数万款独家优惠")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }