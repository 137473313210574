var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "informer", attrs: { "bi-route": "xianbao" } },
    [
      _c("div", { staticClass: "informerLeft" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "tabs" },
          _vm._l(_vm.tabList, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "tabItem",
                class: [{ checkItem: _vm.checktab.id == item.id }],
                attrs: {
                  "bi-click": "fenlei",
                  "data-index": index,
                  "data-gid": item.title
                },
                on: {
                  click: function($event) {
                    _vm.checktab = item
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(item.title) + " "),
                _c("div", { staticClass: "arrow_down" })
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "goodsList" },
          _vm._l(_vm.dataList, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "goodsItem",
                attrs: { id: "qujianlou" + index, "bi-route": "goods" },
                on: {
                  click: function($event) {
                    return _vm.handleUrl(item, index)
                  }
                }
              },
              [
                _c("div", { staticClass: "goodsHead" }, [
                  item.picUrls
                    ? _c("img", { attrs: { src: item.picUrls, alt: "" } })
                    : _c("img", {
                        attrs: { src: require("./images/deful.png"), alt: "" }
                      }),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.handleContent(item.contentCopy))
                    }
                  })
                ]),
                _c("div", { staticClass: "goodsTime" }, [
                  _c("img", {
                    attrs: { src: _vm.getShopImg(_vm.checktab.type), alt: "" }
                  }),
                  _c("div", [
                    _vm._v("发布于" + _vm._s(_vm.handleTime(item.createTime)))
                  ])
                ]),
                _vm._m(1, true)
              ]
            )
          }),
          0
        )
      ]),
      _c("div", { staticClass: "informerLRight" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "imgList" },
          _vm._l(_vm.absList, function(item, index) {
            return _c("div", { key: item.img }, [
              index < 2
                ? _c("div", [
                    _c(
                      "a",
                      {
                        attrs: {
                          "bi-click": "guanggao",
                          "data-title": item.title,
                          "data-index": index,
                          "data-bi360": _vm.bi360,
                          href: item.url,
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: "" + item.img,
                              expression: "`${item.img}`"
                            }
                          ],
                          attrs: { alt: "" }
                        })
                      ]
                    )
                  ])
                : _vm._e()
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "text" }, [_vm._v("优惠线报")]),
        _c("span", [
          _vm._v("淘宝/京东最全最新的一手隐藏优惠，还可和优惠券叠加使用！")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goodsBut" }, [
      _c("p", [_vm._v("去捡漏")]),
      _c("img", {
        attrs: { src: require("@/assets/search/rightbai.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("优惠活动中心")]),
      _c("span", [_vm._v("爆款活动 万里挑一")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }