var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-1200" }, [
    _c("div", { staticClass: "containerHead" }, [
      _c(
        "div",
        { staticClass: "headLeft", attrs: { "bi-route": "fenlei" } },
        _vm._l(_vm.classfilyInfo, function(item) {
          return _c("dl", { key: item.id }, [
            _c("dt", { staticClass: "tit" }, [
              _c("img", {
                staticClass: "ico",
                attrs: { src: item.img, alt: "" }
              }),
              _vm._v(_vm._s(item.name) + " ")
            ]),
            _c(
              "dd",
              _vm._l(item.children, function(citem, index) {
                return _c(
                  "p",
                  {
                    key: index,
                    class: citem.color ? "hot" : "",
                    attrs: {
                      "bi-click": "leimu",
                      "data-leimu_pname": item.name,
                      "data-leimu_name": citem.lable
                    },
                    on: {
                      click: function($event) {
                        return _vm.jumpAitaobao(citem.lable)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(citem.lable) + " ")]
                )
              }),
              0
            )
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "headCenter bg"
        },
        [
          _c("div", { staticClass: "loadImg" }, [
            _c("img", { attrs: { src: _vm.loadingImg, alt: "" } })
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "headCenter",
          attrs: { "bi-route": "lunbo" }
        },
        [
          _c("div", { staticClass: "swiper" }, [
            _c("div", { staticClass: "swiper-container swiperHeader" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.absList.ad_360_carousel, function(item, index) {
                  return _c(
                    "div",
                    { key: item.c_type + index, staticClass: "swiper-slide" },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: item,
                              expression: "item"
                            }
                          ],
                          attrs: {
                            "bi-click": "shang",
                            "data-index": index,
                            "data-title": item.title,
                            "data-bi360": _vm.absList.bi360,
                            target: "_blank"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.img,
                              alt: "",
                              height: "270",
                              width: "605"
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                0
              ),
              _c("div", {
                staticClass: "swiper-button-prev",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              }),
              _c("div", {
                staticClass: "swiper-button-next",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              }),
              _c("div", { staticClass: "swiper-pagination" })
            ])
          ]),
          _c(
            "div",
            { staticClass: "swiperDown" },
            _vm._l(_vm.absList.ad_360_carousel_bottom, function(item, index) {
              return _c("div", { key: index }, [
                _c(
                  "a",
                  {
                    attrs: {
                      "bi-click": "xia",
                      "data-title": item.title,
                      "data-index": index,
                      "data-bi360": _vm.absList.bi360,
                      href: item.url,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: "" + item.img,
                          expression: "`${item.img}`"
                        }
                      ],
                      attrs: { alt: "" }
                    })
                  ]
                )
              ])
            }),
            0
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "headRight", attrs: { "bi-route": "shangcheng" } },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "platform" },
            _vm._l(_vm.platformList, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "platItem",
                  attrs: {
                    "bi-click": "list",
                    "data-title": item.title,
                    "data-bi360": _vm.absList.bi360,
                    "data-index": index
                  },
                  on: {
                    click: function($event) {
                      return _vm.jumpThird(item)
                    }
                  }
                },
                [
                  _c("img", { attrs: { src: item.img, alt: "" } }),
                  _c("div", { staticClass: "mantleItem" }, [
                    _c("div", { staticClass: "mantle" }, [
                      _c("p", [_vm._v(" " + _vm._s(item.title) + " ")]),
                      _c("p", { staticClass: "but" }, [_vm._v("点击进入")])
                    ])
                  ]),
                  item.lable
                    ? _c(
                        "div",
                        {
                          staticClass: "labelText",
                          class: [
                            { wphText: item.id == 3, tbText: item.id == 4 }
                          ]
                        },
                        [_vm._v(" " + _vm._s(item.lable) + " ")]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ]
      )
    ]),
    _vm.discountList.length
      ? _c("div", { staticClass: "dujia", attrs: { "bi-route": "dujia" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.ddqLoad,
                  expression: "!ddqLoad"
                }
              ],
              staticClass: "ddqCardWrap"
            },
            _vm._l(_vm.discountList, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "ddCard",
                  attrs: {
                    "bi-click": "goods",
                    "data-index": index,
                    "data-gid": item.goodsId,
                    "data-bi360": _vm.absList.bi360
                  },
                  on: {
                    click: function($event) {
                      return _vm.jumpDetaile(item)
                    }
                  }
                },
                [
                  item.rights_id
                    ? _c("div", { staticClass: "lowPrice" }, [
                        _c("p", { staticClass: "zhanwei" }),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              Math.round(Math.random() * (10000 - 5000) + 5000)
                            ) + "人已抢"
                          )
                        ])
                      ])
                    : _vm._e(),
                  item.specialText[0]
                    ? _c("div", { staticClass: "custom" }, [
                        _vm._v(" " + _vm._s(item.specialText[0]) + " ")
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "marketgroup",
                          rawName: "v-marketgroup",
                          value: item,
                          expression: "item"
                        }
                      ]
                    },
                    [
                      item.rights_id && !item.cashFiftStuts
                        ? _c("div", { staticClass: "lootAll" }, [
                            _vm._v(" -已抢光- ")
                          ])
                        : _vm._e(),
                      _c("img", {
                        staticClass: "mainImg",
                        attrs: { src: item.mainPic + "_310x310.jpg", alt: "" }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "row1" }, [
                    item.tchaoshi === 1 || item.shopType === 1
                      ? _c("i", {
                          class: [item.tchaoshi ? "maoc" : "tmail", "tagTit"],
                          attrs: { title: item.tchaoshi ? "天猫超市" : "天猫" }
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      { attrs: { title: item.dtitle || item.title } },
                      [_vm._v(_vm._s(item.dtitle || item.title))]
                    )
                  ]),
                  _c("div", { staticClass: "row2" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "flex-end"
                        }
                      },
                      [
                        _c("span", { staticClass: "price1" }, [
                          _c("span", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v("￥")
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "21px",
                                "line-height": "24px"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.rights_id
                                    ? item.actualPrice - item.per_face < 0
                                      ? 0
                                      : item.actualPrice - item.per_face
                                    : item.actualPrice
                                )
                              )
                            ]
                          )
                        ]),
                        _c("span", { staticClass: "price2" }, [
                          _c("span", [_vm._v("￥")]),
                          _c("span", [_vm._v(_vm._s(item.originalPrice))])
                        ]),
                        _c("span", { staticClass: "priceText" }, [
                          _vm._v(_vm._s(item.rights_id ? "补贴价" : "福利价"))
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row3" }, [
                    _vm._v(
                      " " +
                        _vm._s(item.rights_id ? "免费领取" : "立即疯抢") +
                        " "
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("全网商城直达")]),
      _c("span", [_vm._v("一键快捷购物")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }