<template>
  <div class="w-1200">
    <div class="containerHead">
      <div class="headLeft" bi-route="fenlei">
        <dl v-for="item in classfilyInfo" :key="item.id">
          <dt class="tit">
            <img :src="item.img" alt="" class="ico" />{{ item.name }}
          </dt>
          <dd>
            <p
              bi-click="leimu"
              :data-leimu_pname="item.name"
              :data-leimu_name="citem.lable"
              v-for="(citem, index) in item.children"
              :key="index"
              :class="citem.color ? 'hot' : ''"
              @click="jumpAitaobao(citem.lable)"
            >
              {{ citem.lable }}
            </p>
          </dd>
        </dl>
      </div>
      <div v-show="loading" class="headCenter bg">
        <div class="loadImg">
          <img :src="loadingImg" alt="" />
        </div>
      </div>
      <div class="headCenter" bi-route="lunbo" v-show="!loading">
        <div class="swiper">
          <div class="swiper-container swiperHeader">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in absList.ad_360_carousel"
                :key="item.c_type + index"
              >
                <a
                  bi-click="shang"
                  :data-index="index"
                  :data-title="item.title"
                  :data-bi360="absList.bi360"
                  v-jump="item"
                  target="_blank"
                >
                  <img :src="item.img" alt="" height="270" width="605" />
                </a>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev" @click.stop=""></div>
            <div class="swiper-button-next" @click.stop=""></div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="swiperDown">
          <div
            v-for="(item, index) in absList.ad_360_carousel_bottom"
            :key="index"
          >
            <a
              bi-click="xia"
              :data-title="item.title"
              :data-index="index"
              :data-bi360="absList.bi360"
              :href="item.url"
              target="_blank"
            >
              <img v-lazy="`${item.img}`" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="headRight" bi-route="shangcheng">
        <div class="title">
          <span class="text">全网商城直达</span>
          <span>一键快捷购物</span>
        </div>
        <div class="platform">
          <div
            v-for="(item, index) in platformList"
            :key="item.id"
            @click="jumpThird(item)"
            class="platItem"
            bi-click="list"
            :data-title="item.title"
            :data-bi360="absList.bi360"
            :data-index="index"
          >
            <img :src="item.img" alt="" />
            <div class="mantleItem">
              <div class="mantle">
                <p>
                  {{ item.title }}
                </p>
                <p class="but">点击进入</p>
              </div>
            </div>
            <div
              v-if="item.lable"
              class="labelText"
              :class="[{ wphText: item.id == 3, tbText: item.id == 4 }]"
            >
              {{ item.lable }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ddq -->
    <div v-if="discountList.length" class="dujia" bi-route="dujia">
      <div class="ddqCardWrap" v-show="!ddqLoad">
        <div
          v-for="(item, index) in discountList"
          :key="item.id"
          class="ddCard"
          @click="jumpDetaile(item)"
          bi-click="goods"
          :data-index="index"
          :data-gid="item.goodsId"
          :data-bi360="absList.bi360"
        >
          <div v-if="item.rights_id" class="lowPrice">
            <p class="zhanwei"></p>
            <p>{{ Math.round(Math.random() * (10000 - 5000) + 5000) }}人已抢</p>
          </div>
          <div v-if="item.specialText[0]" class="custom">
            {{ item.specialText[0] }}
          </div>
          <div v-marketgroup="item">
            <div v-if="item.rights_id && !item.cashFiftStuts" class="lootAll">
              -已抢光-
            </div>
            <img :src="item.mainPic + '_310x310.jpg'" alt="" class="mainImg" />
          </div>
          <div class="row1">
            <i
              v-if="item.tchaoshi === 1 || item.shopType === 1"
              :class="[item.tchaoshi ? 'maoc' : 'tmail', 'tagTit']"
              :title="item.tchaoshi ? '天猫超市' : '天猫'"
            ></i>
            <span :title="item.dtitle || item.title">{{ item.dtitle || item.title }}</span>
          </div>
          <div class="row2">
            <span style="display: flex; align-items: flex-end">
              <span class="price1">
                <span style="font-size: 14px">￥</span>
                <span style="font-size: 21px; line-height: 24px">{{
                  item.rights_id
                    ? item.actualPrice - item.per_face &lt; 0
                      ? 0
                      : item.actualPrice - item.per_face
                    : item.actualPrice
                }}</span>
              </span>
              <span class="price2">
                <span>￥</span>
                <span>{{ item.originalPrice }}</span>
              </span>
              <span class="priceText">{{
                item.rights_id ? "补贴价" : "福利价"
              }}</span>
            </span>
          </div>
          <div class="row3">
            {{ item.rights_id ? "免费领取" : "立即疯抢" }}
          </div>
        </div>
      </div>
    </div>
    <!-- ddqend -->
  </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
import { getOperateColumn, getSearchGoods, getDdqList } from "@/services";
import apparel from "../header/images/apparel.png";
import delicacy from "../header/images/delicacy.png";
import makeups from "../header/images/makeups.png";
import dailyUse from "../header/images/dailyUse.png";
import hotIcon from "../header/images/hotIcon.png";
import tm from "../header/images/tm.png";
import jd from "../header/images/jd.png";
import wph from "../header/images/wph.png";
import tb from "../header/images/tb.png";
import gm from "../header/images/gm.png";
import sn from "../header/images/sn.png";
import wy from "../header/images/wy.png";
import klhg from "../header/images/klhg.png";
import ddw from "../header/images/ddw.png";

import ios from "../header/images/ios.png";
import icon1688 from "../header/images/1688.png";
import lx from "../header/images/lx.png";

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["marketGroup", "config"]),
  },
  watch: {
    marketGroup(state) {
      this.absList = state;
      this.loading = false;
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  mounted() {
    // console.log(this.config);
    importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
      async () => {
        this.getAdsInfo();
        getDdqList().then((res) => {
          this.ddqLoad = false;
          this.ddqList = res.data.goodsList;
        });
      }
    );
    this.getOperateColumnInfo();
  },
  data() {
    return {
      mySwiper: false,
      ddqSwiper: false,
      classfilyInfo: [
        {
          id: 1,
          name: "热门推荐",
          img: hotIcon,
          children: [
            {
              lable: "清凉饮品",
            },
            {
              lable: "爆款美食",
              color: "hot",
            },
            {
              lable: "1元好物",
            },
            {
              lable: "纸巾",
            },
          ],
        },
        {
          id: 2,
          name: "精选美食",
          img: delicacy,
          children: [
            {
              lable: "吃辣首选",
            },
            {
              lable: "早餐",
            },
            {
              lable: "美味水果",
              color: "hot",
            },
            {
              lable: "零食",
            },
            {
              lable: "坚果",
            },
            {
              lable: "美味饮品",
              color: "hot",
            },
          ],
        },
        {
          id: 3,
          name: "居家日用",
          img: dailyUse,
          children: [
            {
              lable: "百货",
            },
            {
              lable: "低价纸巾",
              color: "hot",
            },
            {
              lable: "换季收纳",
            },
          ],
        },
        {
          id: 4,
          name: "美妆",
          img: makeups,
          children: [
            {
              lable: "爆款口红",
            },
            {
              lable: "保湿水乳",
              color: "hot",
            },
            {
              lable: "面膜",
            },
            {
              lable: "防晒用品",
              color: "hot",
            },
            {
              lable: "身体护理",
            },
            {
              lable: "香水",
            },
          ],
        },
        {
          id: 5,
          name: "个性服饰",
          img: apparel,
          children: [
            {
              lable: "运动服饰",
              color: "hot",
            },
            {
              lable: "男装",
            },
            {
              lable: "女装",
            },
          ],
        },
      ],
      platformList: [
        {
          id: 1,
          img: tm,
          title: "天猫商城",
          lable: "今日更新",
          src: "",
        },

        {
          id: 2,
          img: jd,
          title: "京东大牌",
          lable: "今日更新",
          src: "https://u.jd.com/fMf83T6",
        },

        {
          id: 4,
          img: tb,
          title: "淘宝特价",
          lable: "精选商城",
          src: "",
        },
        {
          id: 3,
          img: wph,
          title: "品牌特卖",
          lable: "大牌抢购",
          src: "https://t.vip.com/Hrxkjg3Sl77?chanTag=gouwu360&aq=1",
        },
        // {
        //   id: 5,
        //   img: gm,
        //   title: "国美电器",
        //   src: "https://www.gome.com.cn/",
        // },
        // {
        //   id: 6,
        //   img: sn,
        //   title: "苏宁商城",
        //   src: "https://www.suning.com/",
        // },
        // {
        //   id: 7,
        //   img: wy,
        //   title: "严选商城",
        //   src: "https://you.163.com/",
        // },
        // {
        //   id: 8,
        //   img: klhg,
        //   title: "海淘好货",
        //   src: "https://www.kaola.com/",
        // },
        {
          id: 8,
          img: ddw,
          title: "当当网",
          src: "https://tb.j5k6.com/MLnE4",
        },
        {
          id: 9,
          img: ios,
          title: "苹果",
          src: "https://tb.j5k6.com/MKNTH",
        },
        {
          id: 10,
          img: icon1688,
          title: "1688",
          src: "https://tb.j5k6.com/MKOpx",
        },
        {
          id: 11,
          img: lx,
          title: "联想",
          src: "https://tb.j5k6.com/MKNE7",
        },
      ],
      absList: {},
      ddqList: [],
      timeDesc: "",
      loading: true,
      ddqLoad: true,
      loadingImg: require("./images/indexLoad.png"),
      discountList: [],
    };
  },
  methods: {
    jumpDetaile(item) {
      if (item.rights_id && item.cashFiftStuts && item.tlj_short_url) {
        return window.open(item.tlj_short_url, "_blank");
      } else if (item.rights_id && !item.cashFiftStuts) {
        return;
      }
      window.open(`index.php?r=l/d&id=${item.id}&u=1`, "_blank");
    },
    jumpThird(item) {
      if (item.title == "天猫商城") {
        window.open(
          `https://www.tmall.com/?ali_trackid=2:${this.config.taobao.pid}:1657702506_018_549268557&union_lens=recoveryid:201_33.60.105.37_7029512_1657702468371;prepvid:201_33.60.105.37_7029512_1657702468371&spm=a231o.13503973.19985674831.1&bxsign=tbkyM2G04hj4nlvUptlo1d49rZEQaMV0taBq7VTyZV947L0xc1UhIEBNv9S5nJdUPLhOT9/qyqQ9U5xrRLMATWZZsLr/8mg/wYvUqvxaQC5JFY=`,
          "_blank"
        );
      } else if (item.title == "淘宝特价") {
        window.open(
          `https://ai.taobao.com/?pid=${this.config.taobao.pid}&union_lens=lensId%3APUB%401657702458%40210549af_097f_181f6c425a3_0403%4001`,
          "_blank"
        );
      } else {
        window.open(item.src, "_blank");
      }
    },
    jumpAitaobao(text) {
      window.open(
        `https://ai.taobao.com/search/index.htm?refpid=${this.config.taobao.pid}&key=${text}&clk1=08ce5cbe4d7cfc067915e462620b0fb8`,
        "_blank"
      );
    },
    async getOperateColumnInfo() {
      const type360 = sessionStorage.getItem("type360");
      const { data } = await getOperateColumn({ id: 2, type360 });
      let gids = Object.keys(data);
      if (!gids.length) return;
      const goodsRes = await getSearchGoods({
        ids: gids.join(","),
        pageSize: 5,
        version: "v1.3.0",
      });
      goodsRes.data.list.map((item) => {
        for (const key in data) {
          const element = data[key];
          if (item.id == Number(key)) {
            item.cashFiftStuts = element.status;
            item.rights_id = element.rights_id;
            item.tlj_short_url = element.tlj_short_url;
            item.per_face = element.per_face;
            item.sort = element.sort;
          }
        }
      });
      goodsRes.data.list.sort((a, b) => a.sort - b.sort);
      this.ddqLoad = false;
      this.discountList = goodsRes.data.list;
    },
    async getAdsInfo() {
      this.marketGroup && (this.absList = this.marketGroup);
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    computeNummber: function (pre, next = 0) {
      if ((pre === 0 && next === 0) || next === 0) {
        return "0%";
      } else {
        return (Number(pre) / Number(next)) * 100 + "%";
      }
    },
    initSwiper() {
      if (!window.Swiper || this.mySwiper) {
        this.mySwiper && this.mySwiper.update();
        return;
      }
      if (this.mySwiper) {
        this.mySwiper.destroy(false);
      }

      if (this.absList.ad_360_carousel) {
        this.loading = false;
        setTimeout(() => {
          this.mySwiper = new Swiper(".swiperHeader", {
            // loop: true, // 循环模式选项
            direction: "horizontal",
            slidesPerView: "auto",
            autoplay: true,
            loop: true,
            slidesPerGroup: 1,
            observer: true,
            observeSlideChildren: true,
            // observerParents: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        }, 100);
      }
    },
  },
  destroyed() {
    if (this.mySwiper) {
      this.mySwiper.destroy(false);
    }
  },
};
</script>

<style lang="less" scoped>
@import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
.w-1200 {
  width: 1200px;
  // min-height: 680px;
  margin: 0 auto;
  margin-top: 15px;
}
/deep/.swiper-pagination-bullet-active {
  background: #fff;
}
.all_tip_container {
  .all_tip {
    width: 1200px;
    height: 40px;
    background: #ffe5cb;
    border-radius: 20px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    padding: 14px 23px;
    box-sizing: border-box;
    img {
      width: 21px;
      height: 16px;
      margin-right: 14px;
    }
    span {
      font-size: 14px;
      color: #dd0000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a.isJump {
      font-size: 14px;
      color: #dd0000;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: #b10000;
      }
    }
  }
}

.loadImg {
  // width: 38px !important;
  margin: 0 auto;
  // margin-top: 10px;
  width: 605px;
  height: 270px;

  // animation: loading 1s linear 0s infinite;
  img {
    width: 605px;
    // height: 270px;
    background-size: 100% 100%;
  }
}
.swiper-button-disabled {
  pointer-events: auto;
}
.ddqLoad {
  margin-top: 125px;
}
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.containerHead {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  background: #f6f6f6;
  .headLeft {
    width: 220px;
    height: 396px;
    background: #fff;
    padding: 10px 12px;
    box-sizing: border-box;
    .tit {
      color: #333333;
      font-size: 15px;
      line-height: 30px;
      font-weight: 700;
    }
    .ico {
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px;
      left: -5px;
    }
    dd {
      margin: 0 0 10px 15px;
      display: flex;
      flex-wrap: wrap;
      max-height: 44px;
      overflow: hidden;
    }
    dd p {
      color: #888888;
      font-size: 14px;
      display: block;
      line-height: 22px;
      margin: 0 8px 0 0;
      cursor: pointer;
      &:hover {
        color: #fe2e54;
      }
    }
    .hot {
      color: #ea2c13;
    }
  }
  .bg {
    // height: 410px !important;
    background: #fff;
  }
  .headCenter {
    width: 605px;
    // height: 270px;
    .swiper {
      height: 270px;
    }
    .swiper-container {
      .swiper-button-prev {
        left: 0;
        background: url("./images/pre.png") center;
        background-size: 100% 100%;
        display: none;
      }
      .swiper-button-next {
        right: 0;
        background: url("./images/next.png") center;
        background-size: 100% 100%;
        display: none;
      }
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background: #fff;
        }
      }
    }
    .swiper-container:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: block;
      }
    }
    .swiper-wrapper {
      height: 270px;
      position: relative;
    }
    .swiper-button-disabled {
      opacity: 0.3 !important;
    }
    .swiper-pagination-bullet-active {
      background: #fff !important;
    }
    .swiper-pagination {
      // text-align: right;
      // padding-right: 20px;
      box-sizing: border-box;
      .swiper-pagination-bullet-active {
        background: #fff !important;
      }
    }

    .swiperDown {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      padding: 10px;
      box-sizing: border-box;
      div {
        height: 106px;
        width: 189px;
        img {
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
          border: none;
        }
      }
    }
  }
  .headRight {
    width: 330px;
    height: 396px;
    padding-top: 6px;
    box-sizing: border-box;
    .title {
      font-size: 12px;
      color: #999999;
      line-height: 24px;
      .text {
        font-weight: Bold;
        font-size: 18px;
        color: #333333;
        margin-right: 8px;
      }
    }
    .platform {
      width: 330px;
      display: flex;
      margin-top: 5px;
      flex-wrap: wrap;
      justify-content: space-between;
      .platItem {
        width: 160px;
        height: 80px;
        margin-top: 10px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .labelText {
          width: 58px;
          height: 20px;
          background: #15c09a;
          border-radius: 87px 2px 2px 87px;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          line-height: 20px;
          position: absolute;
          margin: -83px 0 0 103px;
        }
        .wphText {
          background: #be4bff;
          color: #ffffff;
        }
        .tbText {
          background: #ee3713;
          color: #ffffff;
        }
        .mantleItem {
          display: none;
          transition: top 1s;
          position: relative;
          top: -3px;
          width: 160px;
          height: 80px;
        }
        .mantle {
          width: 100%;
          height: 100%;
          background: rgba(255, 77, 54, 0.8);
          font-weight: Bold;
          font-size: 16px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .but {
            width: 72px;
            height: 25px;
            background: #ffffff;
            border-radius: 13px;
            font-size: 12px;
            color: #ea2c13;
            text-align: center;
            line-height: 25px;
            font-weight: 400;
            margin-top: 6px;
          }
        }
      }
      .platItem:hover {
        .mantleItem {
          display: block;
          top: -83px;
        }
        .labelText {
          display: none;
        }
      }
    }
  }
}
.dujia {
  width: 1200px;
  height: 287px;
  display: flex;
  background: url("./images/djyh.png") no-repeat;
  background-size: 100% 100%;
  padding: 15px 0 15px 210px;
  box-sizing: border-box;
  .ddqCardWrap {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    .ddCard {
      width: 175px;
      height: 257px;
      background-color: #fff;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-right: 23px;
      .lowPrice {
        width: 89px;
        height: 22px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        position: absolute;
        top: 11px;
        left: 10px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .zhanwei {
          width: 6px;
          height: 6px;
          background: #00ff32;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
      .custom {
        width: 79px;
        height: 16px;
        background: #ff6600;
        border-radius: 2px;
        position: absolute;
        top: 149px;
        left: 10px;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .lootAll {
        width: 99px;
        height: 96px;
        background: rgba(0, 0, 0, 0.6);
        font-size: 15px;
        color: #ffffff;
        line-height: 96px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        margin: 25px 0 0 30px;
      }
      .row1 {
        height: 20px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        img {
          width: 14px;
          height: 14px;
          object-fit: contain;
          flex-shrink: 0;
        }
        span {
          flex: 1;
          font-size: 14px;
          color: #333;
          margin-left: 4px;
          width: 148px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        i {
          font-style: normal;
          height: 14px;
          width: 14px;
          background-size: 100% 100%;
          &.tmail {
            background: url("../../../../../berserk/images/tmall.png") center
              no-repeat;
            background-size: contain;
          }
          &.maoc {
            background: url("../../../../../berserk/images/maoc.png") center
              no-repeat;
            background-size: contain;
          }
        }
      }
      .row2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        flex-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        .price1 {
          display: flex;
          color: #fe2e2e;
          align-items: flex-end;
        }
        .price2 {
          color: #ababab;
          font-size: 12px;
          line-height: 20px;
          text-decoration: line-through;
          margin-left: 5px;
          display: flex;
        }
        .priceText {
          font-size: 12px;
          color: #ea2c13;
          line-height: 20px;
          margin-left: 5px;
        }
      }
      .row3 {
        width: 159px;
        height: 23px;
        background: #ea2c13;
        font-weight: Bold;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        margin-top: 7px;
        line-height: 23px;
      }
    }
    .mainImg {
      width: 100%;
      height: 155px;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>