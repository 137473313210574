<template>
  <div class="placeList">
    <div class="hotSale" bi-route="rexiao">
      <div class="head">
        <div class="title">
          <span class="text">TOP1&nbsp;&nbsp;&nbsp;实时热销榜</span>
          <img src="@/assets/search/jiangbei.png" alt="" />
        </div>
        <a class="more" href="/index.php?r=/p" bi-click="gengduo">
          <p>查看更多</p>
          <img src="@/assets/search/right2.png" alt="" />
        </a>
      </div>
      <div class="dataList">
        <div
          class="item"
          v-for="(item, index) in dataList"
          :key="item.id"
          @click="jumpDetaile(item)"
          bi-click="goods"
          :data-index="index"
          :data-gid="item.id"
        >
          <img
            class="topImg"
            :src="require(`@/assets/search/top${index + 1}.png`)"
            alt=""
          />
          <img class="goodsImg" :src="item.mainPic + '_310x310.jpg'" alt="" />
          <div class="goodsName">
            <img
              :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)"
              alt=""
            />
            <p class="name">{{ item.dtitle || item.title }}</p>
          </div>
          <div class="degree">
            2小时热度值<span>{{ item.twoHoursSales | salesNum }}</span>
          </div>
          <div class="goodsPrice">
            <div class="priceLeft">
              <span class="icon">¥</span>
              <span class="price">{{ item.actualPrice }}</span>
              <span v-if="item.originalPrice" class="yPrice">
                ¥{{ item.originalPrice }}
              </span>
            </div>
            <div v-if="item.couponPrice" class="priceRight">
              券{{ item.couponPrice }}元
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="goodThing" bi-route="haowu">
      <div class="head">
        <div class="title">
          <span class="text">TOP2&nbsp;&nbsp;&nbsp;夏日清凉好物</span>
          <img src="@/assets/search/jiangbei.png" alt="" />
        </div>
        <div class="more" @click="toVolume" bi-click="gengduo" :data-bi360="bi360">
          <p>查看更多</p>
          <img src="@/assets/search/right2.png" alt="" />
        </div>
      </div>
      <div class="dataList">
        <div
          class="item"
          v-for="(item, index) in goodThingList"
          :key="item.id"
          @click="jumpDetaile(item)"
          bi-click="goods"
          :data-index="index"
          :data-gid="item.goodsId"
          :data-bi360="bi360"
        >
          <img
            class="topImg"
            :src="require(`@/assets/search/top${index + 1}.png`)"
            alt=""
          />
          <img class="goodsImg" :src="item.mainPic + '_310x310.jpg'" alt="" />
          <div class="goodsName">
            <img
              :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)"
              alt=""
            />
            <p class="name">{{ item.dtitle || item.title }}</p>
          </div>
          <div class="degree">
            近日热度值<span>{{ item.monthSales | salesNum(_, 1) }}</span>
          </div>
          <div class="goodsPrice">
            <div class="priceLeft">
              <span class="icon">¥</span>
              <span class="price">{{ item.actualPrice }}</span>
              <span v-if="item.originalPrice" class="yPrice"
                >¥{{ item.originalPrice }}</span
              >
            </div>
            <div v-if="item.couponPrice" class="priceRight">
              券{{ item.couponPrice }}元
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOperateColumn, getSearchGoods, getTodayData } from "@/services";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["marketGroup"]),
  },
  watch: {
    marketGroup(state) {
      this.bi360 = state.bi360;
    },
  },
  mounted() {
    this.getOperateColumnInfo();
    this.getTodayInfo();
    this.bi360=this.marketGroup.bi360
  },
  data() {
    return {
      dataList: [],
      goodThingList: [],
      bi360: 1,
    };
  },
  methods: {
    toVolume() {
      this.$router.push({ path: "/p", query: { tabsIndex: 2 } });
    },
    jumpDetaile(item) {
      window.open(`index.php?r=l/d&id=${item.id}&u=1`, "_blank");
    },
    getShopImg(haitao, tchaoshi, shopType) {
      if (haitao == 1) return require("@/assets/search/prod_icon2.png");
      if (tchaoshi == 1) return require("@/assets/search/prod_icon3.png");
      if (shopType == 1) return require("@/assets/search/prod_icon1.png");
      return require("@/assets/search/prod_icon1.png");
    },
    getTodayInfo() {
      getTodayData({ rankType: 1, pageSize: 6 }).then((res) => {
        this.dataList = res.data;
      });
    },
    async getOperateColumnInfo() {
      const type360 = sessionStorage.getItem("type360");
      const { data } = await getOperateColumn({ id: 3, type360 });
      let gids = Object.keys(data);
      if (!gids.length) return;
      const goodsRes = await getSearchGoods({
        ids: gids.join(","),
        pageSize: 6,
      });
      goodsRes.data.list.map((item) => {
        for (const key in data) {
          const element = data[key];
          if (item.id == Number(key)) {
            item.sort = element.sort;
          }
        }
      });
      goodsRes.data.list.sort((a, b) => a.sort - b.sort);
      this.goodThingList = goodsRes.data.list.slice(0, 6);
    },
  },
  destroyed() {},
};
</script>

<style lang="less" scoped>
.placeList {
  width: 1200px;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .hotSale,
  .goodThing {
    width: 570px;
    height: 600px;
    background-image: linear-gradient(116deg, #fffbf2 5%, #fff2f2 100%);
    padding: 23px 0 0 20px;
    box-sizing: border-box;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 33px;
      .title {
        font-size: 12px;
        color: #999999;
        display: flex;
        align-items: center;
        .text {
          font-weight: Bold;
          font-size: 24px;
          color: #333333;
          margin-right: 10px;
        }
        img {
          width: 19px;
          height: 26px;
          margin-top: 4px;
        }
      }
      .more {
        font-size: 13px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-top: 3px;
        cursor: pointer;
        img {
          width: 6px;
          height: 11px;
          margin-left: 5px;
          margin-top: 2px;
        }
      }
    }
    .dataList {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 150px;
        margin: 20px 33px 0 0;
        cursor: pointer;
        .topImg {
          width: 64px;
          height: 25px;
          position: absolute;
        }
        .goodsImg {
          width: 100%;
          height: 150px;
        }
        .goodsName {
          display: flex;
          align-items: center;
          margin-top: 10px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
            margin-top: -2px;
          }
          .name {
            font-size: 14px;
            color: #333333;
            font-family: MicrosoftYaHei;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .degree {
          width: 100%;
          height: 21px;
          background: #ffebe7;
          border-radius: 5px;
          font-size: 13px;
          color: #53270f;
          text-align: center;
          line-height: 21px;
          margin-top: 8px;
          span {
            font-size: 15px;
            color: #eb0000;
            font-family: PingFangSC-Medium;
          }
        }
        .goodsPrice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;
          .priceLeft {
            color: #ea2c13;
            max-width: 103px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .icon {
              font-size: 15px;
              margin-right: 2px;
            }
            .price {
              font-size: 20px;
            }
            .yPrice {
              text-decoration: line-through;
              color: #ababab;
              margin-left: 4px;
            }
          }
          .priceRight {
            width: 47px;
            height: 16px;
            background: #ffece0;
            font-size: 12px;
            color: #ff6600;
            line-height: 16px;
            text-align: center;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>