import Header from  './header/Header.vue';
import PlaceList from './placeList/index.vue'
import GoodsStuff from './goodsStuff/GoodsStuff.vue'
import Informer from './informer'

export {
    Header,
    PlaceList,
    GoodsStuff,
    Informer
}