var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "placeList" }, [
    _c("div", { staticClass: "hotSale", attrs: { "bi-route": "rexiao" } }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "dataList" },
        _vm._l(_vm.dataList, function(item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              attrs: {
                "bi-click": "goods",
                "data-index": index,
                "data-gid": item.id
              },
              on: {
                click: function($event) {
                  return _vm.jumpDetaile(item)
                }
              }
            },
            [
              _c("img", {
                staticClass: "topImg",
                attrs: {
                  src: require("@/assets/search/top" + (index + 1) + ".png"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "goodsImg",
                attrs: { src: item.mainPic + "_310x310.jpg", alt: "" }
              }),
              _c("div", { staticClass: "goodsName" }, [
                _c("img", {
                  attrs: {
                    src: _vm.getShopImg(
                      item.haitao,
                      item.tchaoshi,
                      item.shopType
                    ),
                    alt: ""
                  }
                }),
                _c("p", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.dtitle || item.title))
                ])
              ]),
              _c("div", { staticClass: "degree" }, [
                _vm._v(" 2小时热度值"),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("salesNum")(item.twoHoursSales)))
                ])
              ]),
              _c("div", { staticClass: "goodsPrice" }, [
                _c("div", { staticClass: "priceLeft" }, [
                  _c("span", { staticClass: "icon" }, [_vm._v("¥")]),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(item.actualPrice))
                  ]),
                  item.originalPrice
                    ? _c("span", { staticClass: "yPrice" }, [
                        _vm._v(" ¥" + _vm._s(item.originalPrice) + " ")
                      ])
                    : _vm._e()
                ]),
                item.couponPrice
                  ? _c("div", { staticClass: "priceRight" }, [
                      _vm._v(" 券" + _vm._s(item.couponPrice) + "元 ")
                    ])
                  : _vm._e()
              ])
            ]
          )
        }),
        0
      )
    ]),
    _c("div", { staticClass: "goodThing", attrs: { "bi-route": "haowu" } }, [
      _c("div", { staticClass: "head" }, [
        _vm._m(1),
        _c(
          "div",
          {
            staticClass: "more",
            attrs: { "bi-click": "gengduo", "data-bi360": _vm.bi360 },
            on: { click: _vm.toVolume }
          },
          [
            _c("p", [_vm._v("查看更多")]),
            _c("img", {
              attrs: { src: require("@/assets/search/right2.png"), alt: "" }
            })
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "dataList" },
        _vm._l(_vm.goodThingList, function(item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              attrs: {
                "bi-click": "goods",
                "data-index": index,
                "data-gid": item.goodsId,
                "data-bi360": _vm.bi360
              },
              on: {
                click: function($event) {
                  return _vm.jumpDetaile(item)
                }
              }
            },
            [
              _c("img", {
                staticClass: "topImg",
                attrs: {
                  src: require("@/assets/search/top" + (index + 1) + ".png"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "goodsImg",
                attrs: { src: item.mainPic + "_310x310.jpg", alt: "" }
              }),
              _c("div", { staticClass: "goodsName" }, [
                _c("img", {
                  attrs: {
                    src: _vm.getShopImg(
                      item.haitao,
                      item.tchaoshi,
                      item.shopType
                    ),
                    alt: ""
                  }
                }),
                _c("p", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.dtitle || item.title))
                ])
              ]),
              _c("div", { staticClass: "degree" }, [
                _vm._v(" 近日热度值"),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("salesNum")(item.monthSales, _vm._, 1)))
                ])
              ]),
              _c("div", { staticClass: "goodsPrice" }, [
                _c("div", { staticClass: "priceLeft" }, [
                  _c("span", { staticClass: "icon" }, [_vm._v("¥")]),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(item.actualPrice))
                  ]),
                  item.originalPrice
                    ? _c("span", { staticClass: "yPrice" }, [
                        _vm._v("¥" + _vm._s(item.originalPrice))
                      ])
                    : _vm._e()
                ]),
                item.couponPrice
                  ? _c("div", { staticClass: "priceRight" }, [
                      _vm._v(" 券" + _vm._s(item.couponPrice) + "元 ")
                    ])
                  : _vm._e()
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "text" }, [_vm._v("TOP1   实时热销榜")]),
        _c("img", {
          attrs: { src: require("@/assets/search/jiangbei.png"), alt: "" }
        })
      ]),
      _c(
        "a",
        {
          staticClass: "more",
          attrs: { href: "/index.php?r=/p", "bi-click": "gengduo" }
        },
        [
          _c("p", [_vm._v("查看更多")]),
          _c("img", {
            attrs: { src: require("@/assets/search/right2.png"), alt: "" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "text" }, [_vm._v("TOP2   夏日清凉好物")]),
      _c("img", {
        attrs: { src: require("@/assets/search/jiangbei.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }